body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.react-datepicker-wrapper {
  width: 100%;
}

/* Hide scrollbar but keep scroll functionality */
.scrollable-sidebar {
  overflow-y: scroll; /* Enable scrolling */
  scrollbar-width: none; /* For Firefox */
}

.scrollable-sidebar::-webkit-scrollbar {
  width: 0; /* For WebKit browsers */
  background: transparent; /* For WebKit browsers */
}


.custom-bar-chart .apexcharts-bar {
  border-radius: 6px; /* General rounding */
}

.custom-bar-chart .apexcharts-bar:nth-child(odd) {
  border-top-left-radius: 6px;  /* Top left corner for odd bars */
  border-top-right-radius: 6px; /* Top right corner for odd bars */
}

.custom-bar-chart .apexcharts-bar:nth-child(even) {
  border-bottom-left-radius: 6px; /* Bottom left corner for even bars */
  border-bottom-right-radius: 6px; /* Bottom right corner for even bars */
}



::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  height: 6px; /* For horizontal scrollbar */
} 

/* html {
  scrollbar-width: thin;  
  scrollbar-color: #ec4015 #f1f1f1;  
} */

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #c4c3c3; /* Thumb color */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}
