.auth-background {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .auth-image {
    position: absolute;
    top: 45px; /* Adjust as needed */
     /* Optionally constrain width */
    display: flex;
    justify-content: center;
  }
  
  .auth-image img {
    width: 100%; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  .auth-content {
    position: relative;
    z-index: 2; /* Ensure the content is above the background image */
    background: rgba(255, 255, 255, 0.7); /* Optional: Add a semi-transparent background to the content */
    padding: 3.5rem;
    border-radius: 10px;
    margin: 0 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .auth-contents {
    position: relative;
    z-index: 2;
    padding:3.5rem;
    border-radius: 50px;
    margin: 0 1rem;
  }
  @media (max-width:768px){
    .auth-contents{
      padding:1.5rem;
    }
  }

  .auth-backgrounds {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width:768px){
    .auth-backgrounds {
      position: relative;
      width: 100%;
      min-height: 100vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
 